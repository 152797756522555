.services {
  flex: 0 0 auto;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  scroll-snap-align: start;
  height: calc(100vh - 100px);
  /* z-index: -1; */
}

.services-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 60px;

  color: #282860;
}

.services-components {
  display: flex;

  width: 80%;
  height: 550px;

  margin-top: 40px;
}

.services-client-component {
  width: 44%;

  background: #5f6ce1;
  border-radius: 40px;

  position: relative;
}

.services-client-component-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  /* text-align: center; */
  color: #ffffff;

  margin: 30px;
}
.services-client-component-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* text-align: center; */
  line-height: 25px;

  color: #ffffff;

  opacity: 0.8;
  margin: 30px;
}
.services-client-component-button {
  width: 130px;
  height: 40px;

  background: #f8f7f6;
  border-radius: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #5f6ce1;

  margin: 30px;
  cursor: pointer;
}
.services-client-component-image {
  position: absolute;
  bottom: 0;
  right: 30px;

  width: 70%;
}

.services-communication-component {
  width: 28%;

  background: rgba(221, 226, 233, 0.5);
  border-radius: 40px;

  margin-left: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-communication-component-image {
  width: 80%;
}

.services-communication-component-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;

  display: flex;
  align-items: center;

  color: #282860;

  padding-left: 30px;
  padding-right: 30px;
}

.services-communication-component-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.8;

  padding-left: 30px;
  padding-right: 30px;
}

.services-progress-component {
  width: 28%;

  background: #ffffff;
  border: 1px solid rgba(50, 51, 55, 0.1);
  box-shadow: 0px 8px 16px rgba(41, 40, 45, 0.04);
  border-radius: 32px;
  transform: rotate(8deg);

  position: relative;
}

.services-progress-component-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;

  display: flex;
  align-items: center;

  color: #282860;

  margin: 30px;
}

.services-progress-component-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;

  color: #282860;
  opacity: 0.8;

  margin: 30px;
}

.services-progress-component-image {
  width: 80%;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 1250px) {
  .services-client-component-heading {
    font-size: 26px;
  }

  .services-client-component-text {
    font-size: 14px;
  }
  .services-progress-component-heading {
    font-size: 26px;
  }

  .services-communication-component-text {
    font-size: 14px;
  }

  .services-communication-component-heading {
    font-size: 26px;
  }

  .services-progress-component-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 1150px) {
  .services-heading {
    font-size: 60px;
  }

  /* .services-components {
    margin-top: 40px;
  } */

  .services-client-component {
    width: 40%;
  }

  .services-communication-component {
    width: 30%;
  }

  .services-progress-component {
    width: 30%;
    transform: none;
    margin-left: 20px;
  }
}

@media screen and (max-width: 965px) {
  .services-heading {
  }

  .services-client-component {
    width: 100%;
    margin-left: 0;
  }

  .services-communication-component {
    width: 100%;
    justify-content: space-evenly;
    margin-left: 0;
  }

  .services-progress-component {
    width: 100%;
    margin-left: 0;
  }

  .services-client-component-image {
    width: 40%;
  }

  .services-communication-component-image {
    width: 30%;
  }

  .services-progress-component-image {
    height: 60%;
    width: 40%;
  }
}

@media screen and (max-width: 745px) {
  .services-heading {
    font-size: 50px;
  }
  .services-client-component-image {
    width: 50%;
  }
  .services-progress-component-image {
    width: 50%;
  }
  .services-communication-component-image {
    width: 40%;
  }
}

@media screen and (max-width: 600px) {
  .services-heading {
    font-size: 40px;
  }
  .services-client-component-image {
    width: 60%;
  }
  .services-progress-component-image {
    width: 60%;
  }
  .services-communication-component-image {
    width: 50%;
  }
}

@media screen and (max-width: 450px) {
  .services-heading {
    font-size: 30px;
  }
  .services-components {
    margin-top: 10px;
  }

  .services-client-component-heading {
    margin: 15px;
    font-size: 22px;
  }

  .services-client-component-text {
    margin: 15px;
    font-size: 14px;
  }
  .services-client-component-image {
    width: 70%;
  }
  .services-progress-component-image {
    height: 40%;
    width: 70%;
  }
  .services-communication-component-image {
    width: 60%;
  }
}

/* @media screen and (max-width: 300px) {
  .home-banner {
    margin-top: -30%;
    height: 150px;
  }
} */
