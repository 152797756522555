.how-it-works {
  flex: 0 0 auto;

  display: flex;
  flex-direction: column;
  width: 100vw;

  align-items: center;

  scroll-snap-align: start;
  height: calc(100vh - 100px);
  /* z-index: -1; */
}

.how-it-works-wrapper {
  width: 100%;
  /* margin-top: 50px; */
}

.how-it-works-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 60px;

  color: #282860;
  text-align: center;
}

.how-it-works-components {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding-left: 70px;
  padding-right: 70px;
}

.how-it-works-user-story {
  width: 30%;
  height: 650px;
  background: rgba(221, 226, 233, 0.2);
  border-radius: 40px;

  padding: 20px;
}

.how-it-works-user-story img {
  width: 100%;
  padding-top: 20px;
}

.how-it-works-user-story-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;

  color: #282860;
}

.how-it-works-user-story-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;

  color: #282860;

  opacity: 0.8;

  margin-top: 15px;
}

.how-it-works-user-story-concern {
  background: rgba(171, 178, 242, 0.15);
  border: 0.642857px solid rgba(40, 40, 96, 0.1);
  box-shadow: 0px 5.14286px 5.14286px rgba(58, 71, 189, 0.05);
  border-radius: 20.5714px;

  margin-top: 15px;

  padding: 10px;
}

.how-it-works-user-story-concern-id {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #282860;
}

.how-it-works-user-story-concern-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #282860;

  opacity: 0.7;

  margin-top: 5px;
}

.how-it-works-user-story-data-entry {
  display: flex;

  margin-top: 15px;
}

.how-it-works-user-story-data-entry-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.border-dot {
  width: 15px;
  height: 15px;

  background: #5f6ce1;
  border: 1.28571px solid #abb2f2;

  border-radius: 15px;
}

.border-vertical-line {
  height: 200px;
  width: 2px;
  background: #abb2f2;
}

.how-it-works-user-story-data-entry-component {
}

.how-it-works-user-story-data-entry-component-heading {
  font-weight: 500;
  font-size: 16px;

  color: #282860;

  margin-left: 15px;
}

.how-it-works-user-story-data-entry-component-text {
  margin-top: 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: rgba(40, 40, 96, 0.7);
  word-spacing: 2px;
}

.highlighted-text {
  background: #ffffff;
  border: 0.642857px solid #dde2e9;
  border-radius: 5.14286px;

  padding: 5px 10px;
  font-weight: 500;

  color: #282860;
}

.how-it-works-user-analysis {
  width: 70%;
  height: 650px;
  background: rgba(221, 221, 253, 0.5);
  border-radius: 40px;

  margin-left: 16px;

  padding: 20px;
}

.how-it-works-user-analysis-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;

  color: #282860;
}

.how-it-works-user-analysis-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;

  color: #282860;

  opacity: 0.8;

  margin-top: 15px;
}

.how-it-works-user-analysis-graphs {
  display: flex;
  margin-top: 20px;
  width: 100%;
  height: 70%;
}

.how-it-works-user-analysis-graphs img {
  width: 100%;
}

.how-it-works-user-analysis-graph-1 {
  background-color: #ffffff;
  width: 50%;

  border-radius: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
}

.how-it-works-user-analysis-graph-1 > img {
  width: 100%;
}

.how-it-works-user-analysis-graph-1 > div {
  padding: 10px;

  background: rgba(221, 226, 233, 0.15);
  border: 0.912329px solid rgba(40, 40, 96, 0.25);
  border-radius: 16.4219px;

  margin-top: 35px;

  display: flex;

  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;

  padding: 10px;
}

.bulb-icon {
  width: 30px;
}

.how-it-works-user-analysis-graph-2 {
  display: flex;
  flex-direction: column;

  width: 50%;

  margin-left: 25px;
}

.how-it-works-user-analysis-graph-2-1 {
  height: 70%;
  background-color: #ffffff;
  border-radius: 24px;

  display: flex;
  padding: 20px;
}

.how-it-works-user-analysis-graph-2-1 > img {
  width: 60%;
}

.how-it-works-user-analysis-graph-2-1-component {
  display: flex;
  flex-direction: column;

  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;

  margin-left: 5px;

  padding: 10px;

  background: rgba(221, 226, 233, 0.15);
  border: 0.842883px solid rgba(40, 40, 96, 0.25);
  border-radius: 15.1719px;
}

.how-it-works-user-analysis-graph-2-1-componen-head {
  display: flex;
}

.how-it-works-user-analysis-graph-2-1-componen-body {
  margin-top: 15px;
}

.how-it-works-user-analysis-graph-2-2 {
  height: 30%;
  background-color: #ffffff;
  margin-top: 25px;
  border-radius: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.how-it-works-user-analysis-graph-2-2-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  color: #282860;

  opacity: 0.7;
}

.how-it-works-user-analysis-graph-2-2 img {
  width: 100%;

  margin-top: 15px;
}

.how-it-works-component-radio-buttons {
  background: #f8f5ff;
  border-radius: 20px;
  width: 80px;
  text-align: center;
}
@media screen and (max-width: 1250px) {
  .how-it-works-user-analysis-graph-2-1-componen-body {
    font-size: 8px;
  }
}

@media screen and (max-width: 1165px) {
  .how-it-works-wrapper {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .how-it-works-heading {
    font-size: 60px;
  }

  .how-it-works-user-story {
    width: 100%;
    padding-bottom: 0px;
    height: auto;
  }

  .how-it-works-user-story-heading {
    font-size: 20px;
  }

  .how-it-works-user-story-text {
    font-size: 14px;
    line-height: normal;
  }

  .how-it-works-user-story-concern {
    font-size: 12px;
  }

  .how-it-works-user-story-data-entry {
    font-size: 12px;
  }

  .how-it-works-user-story-data-entry-component-heading {
    font-size: 14px;
  }

  .how-it-works-user-story-data-entry-component-text {
    font-size: 12px;
    line-height: 24px;
  }

  .how-it-works-user-analysis {
    width: 100%;
    margin-left: 0px;
  }

  .how-it-works-user-analysis-heading {
    font-size: 25px;
  }

  .how-it-works-user-analysis-graphs {
    justify-content: center;
    margin-top: 10px;
  }

  .how-it-works-user-analysis-graph-1 {
    width: 80%;
  }

  .how-it-works-user-analysis-graph-2 {
    display: none;
  }

  .how-it-works-user-analysis-text {
    font-size: 14px;
    line-height: 25px;
    margin-top: 10px;
  }

  .how-it-works-user-analysis {
    height: auto;
  }

  .how-it-works-user-analysis-graphs {
    height: auto;
  }

  .how-it-works-user-analysis-graph-1 > img {
    width: 70%;
  }

  .how-it-works-user-analysis-graph-1 > div {
    padding: 5px;
    margin-top: 15px;
    font-size: 12px;
    line-height: normal;
  }
}

@media screen and (max-width: 745px) {
  .how-it-works-heading {
    font-size: 50px;
  }
  .how-it-works-user-analysis-heading {
    font-size: 20px;
  }

  .how-it-works-user-analysis-text {
    line-height: normal;
  }

  .how-it-works-user-analysis-graph-1 > img {
    width: 70%;
  }

  .how-it-works-user-analysis-graph-1 > div {
    padding: 5px;
    margin-top: 15px;
    font-size: 12px;
    line-height: normal;
  }
}

@media screen and (max-width: 600px) {
  .how-it-works-heading {
    font-size: 40px;
  }

  .how-it-works-components {
    padding-left: 40px;
    padding-right: 40px;
  }

  .how-it-works-user-story-text {
    margin-top: 10px;
    font-size: 12px;
  }

  .how-it-works-user-story-concern {
    margin-top: 10px;
  }
  .how-it-works-user-story-concern-id {
    font-size: 10px;
  }
  .how-it-works-user-story-concern-text {
    font-size: 10px;
  }

  .how-it-works-user-story-data-entry {
    margin-top: 10px;
  }

  .how-it-works-user-story-data-entry-component-heading {
    font-size: 12px;
  }

  .how-it-works-user-story-data-entry-component-text {
    margin-top: 10px;
    font-size: 10px;
    line-height: 20px;
  }

  .how-it-works-user-analysis-heading {
    font-size: 16px;
  }

  .how-it-works-user-analysis-text {
    font-size: 12px;
    line-height: normal;
    margin-top: 5px;
  }

  .how-it-works-user-analysis-graph-1 > div {
    padding: 5px;
    margin-top: 15px;
    font-size: 12px;
    line-height: normal;
  }

  .bulb-icon {
    width: 20px;
  }
}

@media screen and (max-width: 450px) {
  .how-it-works-heading {
    font-size: 30px;
  }

  .how-it-works-components {
    padding-left: 20px;
    padding-right: 20px;
  }

  .how-it-works-user-analysis {
    height: auto;
  }

  .how-it-works-user-analysis-graph-1 > img {
    width: 100%;
  }

  .how-it-works-user-analysis-graph-1 > div {
    padding: 5px;
    margin-top: 15px;
    font-size: 10px;
    line-height: normal;
  }

  .bulb-icon {
    width: 15px;
  }

  /* .how-it-works-user-story {
    width: 100%;
  }

  .how-it-works-user-analysis {
    width: 100%;
  } */
}

/* @media screen and (max-width: 300px) {
  .how-it-works {
  }
} */
