.App {
  width: 100%;
  position: relative;
}

.components {
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
  overflow-y: hidden;

  /* height: calc(100vh - 100px);
  height: -moz-calc(100vh - 100px);
  height: -webkit-calc(100vh - 100px); */

  scroll-snap-type: x mandatory;
  position: relative;
}

@media screen and (max-width: 965px) {
}
