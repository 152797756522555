:root {
  --app-height: 100%;
}

body {
  margin: 0px;
  background-color: #f5f5f5;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  height: var(--app-height);
}
