.product {
  flex: 0 0 auto;
  width: 100vw;

  display: flex;

  justify-content: center;

  scroll-snap-align: start;
  /* z-index: -1; */
}

.product-wrapper {
  width: 90%;

  background: rgba(221, 226, 233, 0.2);

  border-radius: 40px;

  display: flex;
  flex-direction: column;
}

.product-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 60px;

  text-align: center;

  color: #282860;
  margin: 20px;
}

.product-component {
  display: flex;
  align-items: center;
  padding: 10px;
}

.product-component-image {
  width: 70%;
}

.product-component-text {
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-between;
}

.product-component-text-heading {
  padding: 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #282860;

  opacity: 0.8;
  margin-bottom: 20px;
}

.product-component-text-data {
  padding: 20px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;

  display: flex;
  align-items: center;

  color: #282860;

  opacity: 0.8;
  margin-bottom: 20px;
}

.product-component-notify {
  margin-top: 20px;
}

.product-component-notify-wrapper {
  width: 100%;
  display: flex;

  border: 2px solid #282860;
  border-top: none;
  border-left: none;
  border-right: none;
}

.product-component-notify-success-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;

  border: 2px solid #282860;
  border-top: none;
  border-left: none;
  border-right: none;
}
.product-component-notify-success-message {
  margin-right: 20px;
  text-align: center;
}
.product-component-notify-success-icon {
  width: 40px;
}

.product-component-notify-wrapper input {
  width: 60%;
  height: 40px;

  outline: none;
  border: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);

  font-size: 16px;
}

.product-component-notify-on-launch-button {
  width: 180px;
  height: 40px;
  background: #5f6ce1;
  border-radius: 80px;
  color: #fffdfc;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  cursor: pointer;
  margin-bottom: 5px;
}

@media screen and (max-width: 1250px) {
  .product-heading {
    font-size: 50px;
  }
  .product-component-image {
    width: 65%;
  }
}

@media screen and (max-width: 1150px) {
  .product-component-image {
    width: 60%;
  }
}

@media screen and (max-width: 965px) {
  .product {
    overflow-y: scroll !important;
  }

  .product-component {
    flex-direction: column;
  }

  .product-heading {
    font-size: 50px;
  }

  .product-component-image {
    width: 90%;
  }

  .product-component-notify {
    width: 90%;
  }

  .product-component-notify-wrapper input {
    width: 80%;
  }
}

@media screen and (max-width: 745px) {
  .product-heading {
    font-size: 50px;
  }
}

@media screen and (max-width: 600px) {
  .product-heading {
    font-size: 40px;
  }
}

@media screen and (max-width: 450px) {
  .product-heading {
    font-size: 30px;
  }
}

/* @media screen and (max-width: 300px) {
  .home-banner {
    margin-top: -30%;
    height: 150px;
  }
} */
