.participate {
  flex: 0 0 auto;
  width: 100vw;

  display: flex;
  flex-direction: column;

  align-items: center;

  scroll-snap-align: start;
  height: calc(100vh - 100px);
  /* z-index: -1; */
}

.participate-wrapper {
  width: 100%;
  margin-top: 50px;
  height: 100%;

  position: relative;
}

.participate-components {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.participate-survey {
  display: flex;
  height: 70%;
  width: 100%;

  position: relative;
}

.participate-survey-image {
  width: 50%;
  height: 100%;
  background: linear-gradient(70.88deg, #5f6ce1 -45.26%, #ddddfd 68.26%);
  border-radius: 40px;

  position: absolute;
  left: 70px;
}

.participate-survey-image img {
  position: absolute;
  top: 40px;
  right: 150px;
  width: 70%;
}

.participate-survey-video {
  width: 50%;
  height: 100%;
  background: linear-gradient(70.88deg, #5f6ce1 -45.26%, #ddddfd 68.26%);
  border-radius: 40px;

  position: absolute;
  left: 70px;
}

.participate-survey-video-player {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 70%;
  height: 100%;
  margin-left: 20px;
}

.participate-survey-video-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;

  color: #282860;
  height: 20%;
}

.participate-survey-video-player video {
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
}

.participate-survey-text {
  width: 50%;
  height: 100%;
  background: #ffffff;
  border-radius: 40px;
  margin-right: -50px;

  position: absolute;
  right: 140px;

  display: flex;
  flex-direction: column;

  /* padding-top: 50px; */
}

.participate-survey-text-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 60px;

  color: #282860;

  margin-top: 20px;
  margin-bottom: 20px;

  margin-left: 50px;
}

.participate-survey-text-data {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;

  color: #282860;

  opacity: 0.8;

  margin-bottom: 30px;
  margin-left: 50px;

  width: 80%;
}

.participate-survey-text-button-group {
  display: flex;
  margin-left: 50px;
}

.participate-survey-text-button-therapist {
  background: #5f6ce1;
  border-radius: 80px;

  width: 200px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}

.participate-survey-text-button-client {
  margin-left: 50px;

  background: #ffffff;
  border: 2px solid #5f6ce1;
  border-radius: 80px;

  width: 200px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #5f6ce1;
  cursor: pointer;
  text-decoration: underline;
}

.participate-footer {
  display: flex;
  flex-direction: column;

  width: 70%;

  position: absolute;
  bottom: 50px;
  right: 50px;
}

.participate-footer-logo {
  width: 10%;
}

.participate-footer-text {
  display: flex;

  font-weight: 400;
  font-size: 14px;
  line-height: 26px;

  color: #282860;

  opacity: 0.8;

  margin-top: 15px;
}

.participate-footer-text-data {
  font-style: italic;
}

.participate-footer-text-links {
  font-style: normal;
  opacity: 1;
  text-decoration: underline;

  display: flex;
  justify-content: space-evenly;
}

.participate-footer-text-link {
  margin-left: 100px;
  cursor: pointer;
}

.participate-footer-links {
  display: flex;

  margin-top: 15px;
  justify-content: space-between;
}

.participate-footer-social-link {
  cursor: pointer;
  margin-right: 50px;
}

.participate-footer-copyright {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;

  color: #282860;

  opacity: 0.8;
}

@media screen and (max-width: 1100px) {
  .participate-survey-text-heading {
    font-size: 50px;
  }

  .participate-footer {
    bottom: 0px;
  }

  .participate-survey-video-player {
    width: 65%;
    margin-left: 20px;
  }

  .participate-survey-video-heading {
    height: 30%;
  }
}

@media screen and (max-width: 965px) {
  .participate {
    overflow-y: scroll;
  }
  .participate-wrapper {
    margin-top: 0px;
    height: auto;
    width: 90%;
  }

  .participate-components {
    align-items: center;
    height: auto;
    justify-content: center;
  }

  .participate-footer {
    width: 100%;
  }

  .participate-footer-text {
    flex-direction: column;
  }

  .participate-survey-text-button-group {
    flex-direction: column;
    margin-left: 50px;
  }

  .participate-survey {
    flex-direction: column;
  }

  .participate-survey-text {
    background-color: unset;
  }

  .participate-survey-image {
    width: 100%;
    position: unset;
  }

  .participate-survey-image img {
    width: 100%;
    position: unset;
  }

  .participate-survey-video {
    width: 100%;
    position: unset;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .participate-survey-video-player {
    width: unset;
    padding: 20px;
  }

  .participate-survey-video-heading {
    padding-bottom: 30px;
  }

  .participate-survey-text {
    width: 100%;
    position: unset;
  }

  .participate-footer {
    position: unset;
    flex-direction: column;
    margin-left: 50px;
    margin-top: 30px;
  }

  .participate-footer-text {
    position: unset;
  }

  .participate-footer-text-links {
    flex-direction: column;
    position: unset;
  }

  .participate-footer-links {
    flex-direction: column;
    position: unset;
  }

  .participate-survey-text-button-client {
    margin-left: 0px;
    margin-top: 20px;
  }

  .participate-footer-logo {
    width: 50%;
  }

  .participate-footer-text-link {
    margin-left: 0px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 610px) {
  .participate-survey-text-heading {
    font-size: 50px;
  }
  .participate-survey-video-heading {
    font-size: 25px;
  }
}

@media screen and (max-width: 530px) {
  .participate-survey-text-heading {
    font-size: 40px;
  }
}

@media screen and (max-width: 450px) {
  .participate-survey-text-heading {
    font-size: 30px;
    margin-left: 30px;
  }

  .participate-survey-text-data {
    margin-left: 30px;
  }

  .participate-survey-text-button-group {
    margin-left: 30px;
  }

  .participate-footer {
    margin-left: 30px;
  }

  .participate-survey-video-heading {
    font-size: 20px;
  }
}
