.home {
  flex: 0 0 auto;
  width: 100vw;

  display: grid;
  grid-template-areas:
    "heading heading text"
    "notify banner banner"
    "emotion-cradle banner banner";

  grid-template-columns: 1fr 0.3fr 1.4fr;
  grid-template-rows: 1fr 0.5fr 1fr;

  column-gap: 40px;

  scroll-snap-align: start;
}

/*****************************/
/* HOME HEADING              */
/*****************************/

.home-heading {
  grid-area: heading;

  font-weight: 800;
  font-size: 50px;

  margin-left: 10%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  color: #282860;
}

/*****************************/
/* HOME TEXT                  */
/*****************************/

.home-text {
  grid-area: text;

  margin-right: 10%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  font-weight: 500;
  font-size: 16px;
  color: #282860;
}

.home-text-link {
  display: flex;
  justify-self: center;
  margin-top: 20px;

  color: #5f6ce1;
  cursor: pointer;
}

.home-text-link img {
  margin-left: 10px;
}

/*****************************/
/* HOME NOTIFY              */
/*****************************/

.home-notify {
  grid-area: notify;
  margin-left: 10%;
}

.home-notify-wrapper {
  width: 100%;
  display: flex;

  border: 2px solid #282860;
  border-top: none;
  border-left: none;
  border-right: none;
}

.home-notify-on-launch-button {
  width: 180px;
  height: 40px;
  background: #5f6ce1;
  border-radius: 80px;
  color: #fffdfc;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  cursor: pointer;
  margin-bottom: 5px;
}

.home-notify-success-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;

  border: 2px solid #282860;
  border-top: none;
  border-left: none;
  border-right: none;
}
.home-notify-success-message {
  margin-right: 20px;
}
.home-notify-success-icon {
  width: 40px;
}

.home-notify-wrapper input {
  width: 60%;
  height: 40px;

  outline: none;
  border: none;
  box-shadow: none;
  background-color: #f5f5f5;

  font-size: 16px;
}

/*****************************/
/* HOME EMOTION CRADLE      */
/*****************************/

.home-emotion-cradle {
  grid-area: emotion-cradle;
  margin-left: 10%;

  padding-left: 5%;
  padding-right: 5%;
}

.home-emotion-cradle-wrapper {
  background-color: #ffe5da;
  border-radius: 40px;
  height: 250px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-emotion-cradle-wrapper img {
  width: 300px;
}

.home-emotion-cradle-text {
  text-align: center;
  margin-top: 30px;

  padding: 2%;

  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  color: #282860;
}

/*****************************/
/* HOME BANNER               */
/*****************************/

.home-banner {
  grid-area: banner;
  margin-right: 10%;
  height: 400px;
  width: 70%;

  display: flex;

  border-radius: 40px;

  background-color: #d1d6fe;
}

.home-banner-data {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 5%;
  width: 80%;

  padding: 2%;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #282860;

  position: relative;
}

.home-banner-data > span {
  font-style: italic;
  margin-top: 40px;

  text-align: left;
}

.home-banner-image {
  width: 20%;
  position: relative;
}

.home-banner-image img {
  position: absolute;
  height: 120%;
  bottom: 0px;
  right: -220px;
  z-index: 0;
}

/*****************************/
/* HOME BANNER COMPONENT     */
/*****************************/

.home-banner-component {
  width: 350px;
  height: 140px;
  background-color: #fff;
  border-radius: 40px;

  position: absolute;
  bottom: -60px;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-banner-component-radio-buttons {
  width: 120px;
  margin-top: 15px;
  background: #f8f5ff;
  border-radius: 40px;
  text-align: center;
}

.home-banner-component-text {
  font-size: 14px;
  padding: 5px;
  margin-top: 15px;
  text-align: center;
}

.dot {
  height: 10px;
  width: 10px;
  margin-left: 10px;
  background: #c3c9ff;
  border-radius: 50%;
  display: inline-block;
}

.highlighted-dot {
  height: 10px;
  width: 10px;
  margin-left: 10px;
  background: #5f6ce1;
  border-radius: 50%;
  display: inline-block;
}

/*****************************/
/* REQUEST INFO              */
/*****************************/

.request-info {
  flex: 0 0 auto;
  width: 100vw;
  scroll-snap-align: start;
}

.request-info-button {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  width: 150px;
  height: 40px;
  border-radius: 80px;

  background-color: #282860;
  color: #fffdfc;
}

@media screen and (max-width: 1350px) {
  .home-heading {
    font-size: 40px;
  }
}

@media screen and (max-width: 1100px) {
  .home-heading {
    font-size: 35px;
  }
}

@media screen and (max-width: 965px) {
  .home {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .home-heading {
    margin-right: 10%;
    font-size: 50px;
  }

  .home-banner {
    margin-left: 10%;
    margin-top: -10px;
    height: 300px;
  }

  .home-banner-data {
    font-size: 18px;
  }

  .home-banner-component {
    bottom: -100px;
  }

  .home-banner-component-text {
    font-size: 12px;
  }

  .home-banner-image img {
    right: -150px;
  }

  .home-notify {
    margin-right: 10%;
  }

  .home-notify-wrapper input {
    width: 80%;
  }

  .home-banner-component {
    bottom: -70px;
  }

  .home-notify {
    margin-top: 20px;
  }

  .request-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .home-text {
    width: 80%;
    margin-right: 0;
    margin-top: 40px;
  }

  .request-info-button {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    width: 150px;
    height: 40px;
    border-radius: 80px;

    background-color: #282860;
    color: #fffdfc;

    margin-top: 50px;
  }

  .home-emotion-cradle {
    margin-left: 0;
  }
}

@media screen and (max-width: 745px) {
  .home-heading {
    font-size: 40px;
    margin-top: -50px;
  }

  .home-banner {
    margin-top: -70px;
    height: 250px;
  }

  .home-banner-data {
    font-size: 14px;
    width: 70%;
  }

  .home-banner-component {
    bottom: -100px;
  }

  .home-banner-component-text {
    font-size: 10px;
  }

  .home-banner-image img {
    right: -120px;
  }

  .home-banner-component {
    bottom: -100px;
    width: 250px;
    height: 120px;
  }

  .home-banner-component-radio-buttons {
    width: 80px;
  }
  .dot {
    width: 6px;
    height: 6px;
    margin-left: 5px;
  }

  .highlighted-dot {
    width: 6px;
    height: 6px;
    margin-left: 5px;
  }
  /* .home-notify {
    margin-top: 20px;
  } */
}

@media screen and (max-width: 600px) {
  .home-heading {
    font-size: 30px;
    margin-top: -50px;
  }

  .home-notify-on-launch-button {
    font-size: 14px;
  }

  .home-banner {
    margin-top: -80px;
    height: 200px;
  }

  .home-banner-data {
    width: 50%;
  }

  .home-banner-data > span {
    margin-top: 20px;
  }

  .home-banner-component {
    bottom: -120px;
    left: 20%;
  }
}

@media screen and (max-width: 450px) {
  .home-heading {
    font-size: 25px;
    margin-top: -80px;
  }

  .home-banner {
    margin-top: -150px;
    height: 150px;
  }

  .home-banner-data {
    font-size: 10px;
  }

  .home-banner-image img {
    right: -80px;
  }

  .home-banner-component {
    bottom: -150px;
    left: 15%;
    background: #e9ebff;
    border: 1px solid #d1d6fe;
    color: #5f6ce1;
  }

  .home-banner-component-radio-buttons {
    background: #f8f5ff;
  }

  .home-text {
    font-size: 14px;
  }

  .home-emotion-cradle-wrapper {
    height: 230px;
  }

  .home-emotion-cradle-wrapper img {
    width: 250px;
  }
}

/* @media screen and (max-width: 300px) {
  .home-banner {
    margin-top: -30%;
    height: 150px;
  }
} */
