.blog {
  flex: 0 0 auto;
  width: 100vw;

  display: flex;
  flex-direction: column;

  align-items: center;

  scroll-snap-align: start;
  height: calc(100vh - 100px);

  /* z-index: -1; */
}

.blog-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5%;
}

.blog-container {
  display: flex;
  flex-wrap: wrap;
}

.blog-post {
  width: 30rem;
  height: 20rem;
  border-radius: 40px;
  background: #f8f9fb;
  background-color: red;
  margin: 15px;
}
