.navbar {
  display: flex;
  height: 60px;
  padding-top: 30px;
  justify-content: space-between;
}

.navbar-contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  font-weight: 500;
  font-size: 16px;
}

.navbar-contents-list {
  display: flex;
  margin-left: 80px;

  color: #282860;
}

.navbar-contents-item {
  margin-right: 40px;
  cursor: pointer;
}

.highlighted {
  color: #4a4aa5;
}

.navbar-contents-button {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  width: 150px;
  height: 40px;
  border-radius: 80px;

  background-color: #282860;
  color: #fffdfc;
}

.navbar-drawer {
  display: none;
}

.navbar-drawer-icon {
  cursor: pointer;
}

.navbar-drawer-component {
  position: absolute;
  top: 100px;
  right: 0px;

  background: #5f6ce1;
  border-radius: 32px 0px 0px 32px;

  /* width: 80vw; */
  max-width: 400px;
  height: 80vh;
}

.external_link_nav_drawer {
  width: 10px;
  filter: brightness(0) invert(1);
}

.external_link_nav_list {
  width: 10px;
}

@media screen and (max-width: 965px) {
  .navbar-contents {
    display: none;
  }

  .navbar-drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .navbar-contents-list {
    flex-direction: column;
    color: #fff;
    margin-left: 40px;
  }

  .navbar-contents-item {
    margin-top: 30px;
    cursor: pointer;
  }

  .highlighted {
    color: #fff;
    text-decoration: underline;
  }

  .navbar-contents-button {
    margin-left: 40px;
    margin-top: 50px;
    background-color: #fff;
    color: rgba(95, 108, 225, 1);
  }

  .navbar-drawer-footer {
    position: absolute;
    flex-direction: column;
    bottom: 20px;
    left: 40px;
  }

  .navbar-drawer-footer img {
    margin: 10px;
    cursor: pointer;
  }
}
